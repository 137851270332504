import React, { useContext } from "react";
import i18n from "i18next";
import { LoadingContext } from "../context/LoadingContext";

const ChallengeSolution = ({ arrayData }) => {
    const { windowWidth } = useContext(LoadingContext);

  return (
    <div className={windowWidth>1025 ? "challenge-solution-container desktop:px-20 text text-left mt-24 mb-6": "challenge-solution-container desktop:px-20 text text-left mt-12 mb-6"}>
      <div className={windowWidth>1025 ? "challenge-section": "challenge-sectionMobile"}>
        <h2 className="challenge-title pr-4">
          {i18n.t("pageMediaSuccessStories.titleChallenge")}
        </h2>
        <p className="challenge-text pr-16">{arrayData.challenge}</p>
      </div>
      <div className="solution-section pr-4">
        <h2 className={windowWidth<1025 ? "solution-title mt-10 pl-4": "solution-title"}>
          {i18n.t("pageMediaSuccessStories.titleSolution")}
        </h2>
        <p className={windowWidth<1025 ? "solution-text pl-4": "solution-text"}>{arrayData.solution}</p>
      </div>
    </div>
  );
};

export default ChallengeSolution;
