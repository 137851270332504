import React, { useState, useEffect, useRef } from "react";
import i18n from "i18next";
// Layout
import Layout from "../components/layout";
import SEO from "../components/seo";
import { withNamespaces } from "react-i18next";
import AxaColpatria from "../images/xMediaSuccessStories/Stories/AxaColpatria.png"
import Gapsi from "../images/xMediaSuccessStories/Stories/Gapsi.png"
import Education from "../images/xMediaSuccessStories/Stories/Education.png"
import Finance from "../images/xMediaSuccessStories/Stories/Finance.png"
import Dafiti from "../images/xMediaSuccessStories/Stories/Dafiti.png"
import Chubb from "../images/xMediaSuccessStories/Stories/Chubb.png"

import { useStaticQuery, graphql } from "gatsby";
import BannerOpenSuccessStorie from "../components/xMediaSuccessStories/BannerOpenSuccessStorie";
import ChallengeSolution from "../components/xMediaSuccessStories/ChallengeSolution";
import ImageOpenSuccessStory from "../components/xMediaSuccessStories/ImagesOpenSuccessStory";
import TechResultsOpenSuccessStory from "../components/xMediaSuccessStories/TechResultsOpenSuccessStory";
import BtnsOpenSuccessStory from "../components/xMediaSuccessStories/BtnsOpenSuccessStory";

//Technologies
import netMVC from "../images/serviceDevCloud/Programming languages/netmvc.png";
import html from "../images/technology/HTML5_Logo_512.png";
import css from "../images/technology/CSS.3.svg.png";
import java from "../images/technology/java.png";
import mysql from "../images/technology/mysql.png";
import sqlserver from "../images/serviceDevCloud/Databases/MicrosoftSQLServer.png";
import jquery from "../images/technology/Jquery.png";
import go from "../images/technology/go.png";
import netf from "../images/serviceDevCloud/Programming languages/netmvc.png";
import oraclecloud from "../images/technology/oracleCloud.png";
import oraclepl from "../images/technology/Oracle-plSql.jpg"
import php from "../images/technology/php.png";
import visualstudio from "../images/technology/visualstudio.png";
import xamarin from "../images/home/Experts/Xamarin.png";
import kubernets from "../images/technology/Kubernetes-Logo.png";
import docker from "../images/exsisAcademy/docker.png";
import netcore from "../images/technology/netcore.png";
import csharp from "../images/technology/c#.png";
import angular from "../images/technology/angular.png";
import entityf from "../images/technology/entityframework.png";
import android from "../images/technology/android.png";
import kotlin from "../images/technology/Kotlin.png";
import axagraph from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/axaGraph.jpg";
import chubbTech from "../images/xMediaSuccessStories/ChubbTech.png";
import miro from "../images/technology/miro.png";
import jira from "../images/technology/jira.png";
import powerbi from "../images/technology/PowerBI.png";
import azureL from "../images/technology/azureLogo.png";
import appiumL from "../images/technology/appiumLogo.png";
import python from "../images/technology/python.png";
import awsCloud from "../images/technology/awscloud.png";
import citrix from "../images/technology/citrix.png";

//logos
import logoAxa from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/axxa-colpatria-logo.png";
import logoDafiti from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/dafiti-logo.png";
import logoEducation from "../images/xMediaSuccessStories/Stories/BannerEducation.png";
import logoFinance from "../images/xMediaSuccessStories/Stories/BannerFinanzas.png";
import logoGapsi from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/gapsi-logo.png";
import logoChubb from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/chubb-logo.png";

//images
import AxaColpatria1 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/AxaColpatria1.png"
import AxaColpatria2 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/AxaColpatria2.png"
import AxaColpatria3 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/AxaColpatria3.png"

import Gapsi1 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Gapsi1.png";
import Gapsi2 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Gapsi2.png";
import Gapsi3 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Gapsi3.png";

import Education1 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Education1.png"
import Education2 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Education2.png"
import Education3 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Education3.png"

import Finance1 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Finance1.png"
import Finance2 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Finance2.png"
import Finance3 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Finance3.png"

import Dafiti1 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Dafiti1.png"
import Dafiti2 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Dafiti2.png"
import Dafiti3 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Dafiti3.png"

import Chubb1 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Chubb1.png"
import Chubb2 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Chubb2.png"
import Chubb3 from "../images/xMediaSuccessStories/ImagesOpenSuccessStory/Chubb3.png"

const OpenSuccesStories = () => {
  const [descriptionTitleBanner, setDescriptionTitleBanner] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const storiesOpen = [
    {
      img: AxaColpatria,
      logo: logoAxa,
      title: i18n.t("pageMediaSuccessStories.axaColpatria.title"),
      page: "axaColpatria",
      challenge: i18n.t("pageMediaSuccessStories.axaColpatria.challenge"),
      solution: i18n.t("pageMediaSuccessStories.axaColpatria.solution"),
      results: [
       i18n.t("pageMediaSuccessStories.axaColpatria.results"),
      ],
      tech: [netMVC, html, css, java, mysql, jquery, sqlserver],
      graph: axagraph,
      images: [AxaColpatria1, AxaColpatria2, AxaColpatria3]
    },
    {
      img: Gapsi,
      logo: logoGapsi,
      title: i18n.t("pageMediaSuccessStories.gapsi.title"),
      page: "gapsi",
      challenge: i18n.t("pageMediaSuccessStories.gapsi.challenge"),
      solution: i18n.t("pageMediaSuccessStories.gapsi.solution"),
      results: [
        i18n.t("pageMediaSuccessStories.gapsi.results1"),
        i18n.t("pageMediaSuccessStories.gapsi.results2"),
        i18n.t("pageMediaSuccessStories.gapsi.results3"),
      ],
      tech: [android, kotlin],
      images: [Gapsi1, Gapsi2, Gapsi3]
    },
    {
      img: Education,
      logo: logoEducation,
      title: i18n.t("pageMediaSuccessStories.education.title"),
      page: "education",
      challenge: i18n.t("pageMediaSuccessStories.education.challenge"),
      solution: i18n.t("pageMediaSuccessStories.education.solution"),
      results: [
        i18n.t("pageMediaSuccessStories.education.results1"),
        i18n.t("pageMediaSuccessStories.education.results2"),
        i18n.t("pageMediaSuccessStories.education.results3"),
      ],
      tech: [java, xamarin, kubernets, docker],
      images: [Education1, Education2, Education3]
    },
    {
      img: Finance,
      logo: logoFinance,
      title: i18n.t("pageMediaSuccessStories.finance.title"),
      page: "finance",
      challenge: i18n.t("pageMediaSuccessStories.finance.challenge"),
      solution: i18n.t("pageMediaSuccessStories.finance.solution"),
      results: [
        i18n.t("pageMediaSuccessStories.finance.results1"),
        i18n.t("pageMediaSuccessStories.finance.results2"),
        i18n.t("pageMediaSuccessStories.finance.results3"),
        i18n.t("pageMediaSuccessStories.finance.results4"),
      ],
      tech: [netcore, csharp, angular, entityf],
      images: [Finance1, Finance2, Finance3]
    },
    {
      img: Dafiti,
      logo: logoDafiti,
      title: i18n.t("pageMediaSuccessStories.dafiti.title"),
      page: "dafiti",
      challenge: i18n.t("pageMediaSuccessStories.dafiti.challenge"),
      solution: i18n.t("pageMediaSuccessStories.dafiti.solution"),
      results: [
        i18n.t("pageMediaSuccessStories.dafiti.results"),
      ],
      tech: [oraclepl, go, netf, oraclecloud, sqlserver, java, php, visualstudio],
      images: [Dafiti1, Dafiti2, Dafiti3]
    },
    {
      img: Chubb,
      logo: logoChubb,
      title: i18n.t("pageMediaSuccessStories.chubb.title"),
      page: "chubb",
      challenge: i18n.t("pageMediaSuccessStories.chubb.challenge"),
      solution: i18n.t("pageMediaSuccessStories.chubb.solution"),
      results: [
        i18n.t("pageMediaSuccessStories.chubb.results1"),
        i18n.t("pageMediaSuccessStories.chubb.results2"),
        i18n.t("pageMediaSuccessStories.chubb.results3"),
      ],
      tech: [miro, jira, powerbi, azureL, netf, appiumL, sqlserver, csharp, oraclepl, python, citrix, awsCloud],
      images: [Chubb1, Chubb2, Chubb3]
    }
  ]

  // Use useStaticQuery to get the data
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  // Get the current path and extract the nameProject
  const currentPath = typeof window !== "undefined" ? window.location.pathname : "";
  const nameProject = currentPath.replace("/open-success-stories/", "");
  const selectedStory = storiesOpen.find(story => story.page === nameProject);
  const arrayTitle = selectedStory ? { title: selectedStory.title } : {};
  const arrayData = selectedStory ? selectedStory : {};
  const logoImage = selectedStory ? selectedStory.logo : null;

  return (
    <Layout>
        <SEO title={"X Media Success Stories | Exsis Digital Angels"} description={"Nearshore Agile Teams | Specialists in Tech Talent"} />
      
       <BannerOpenSuccessStorie
          logoImage={logoImage} 
          arrayTitle={arrayTitle}
          nameProject={nameProject}>
        </BannerOpenSuccessStorie>
           
        <ChallengeSolution arrayData={arrayData}/>
        <ImageOpenSuccessStory arrayData={arrayData}/>
        <TechResultsOpenSuccessStory arrayData={arrayData}/>
        <BtnsOpenSuccessStory nameProject={nameProject}/>
    </Layout>
  );
};

export default  withNamespaces()(OpenSuccesStories);
