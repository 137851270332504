import React, { useContext } from "react";
import i18n from "i18next";
import whatsappIcon from "../../images/xMediaBlogArticle/icons/whatsapp.png"
import facebookIcon from "../../images/xMediaBlogArticle/icons/facebook.png"
import twitterIcon from "../../images/xMediaBlogArticle/icons/twitter.png"
import linkedinIcon from "../../images/xMediaBlogArticle/icons/LinkedIn.png"
import arrowLeftShort from "../../images/xMediaBlogArticle/icons/leftArrowShort.svg"
import arrowRigthShort from "../../images/xMediaBlogArticle/icons/rigthArrowShort.svg"
import { LoadingContext } from "../context/LoadingContext";
import { Link } from "@reach/router";

const BtnsOpenSuccessStory = ({nameProject}) => {
    const { windowWidth } = useContext(LoadingContext);
    const currentUrl = typeof window !== "undefined" ? window.location.href : ""

    const stories = [
        {
          title: i18n.t("pageMediaSuccessStories.axaColpatria.title")+" "+i18n.t("pageMediaSuccessStories.story"),
          page: "axaColpatria"
        },
        {
          title: i18n.t("pageMediaSuccessStories.dafiti.title")+" "+i18n.t("pageMediaSuccessStories.story"),
          page: "dafiti"
        },
        {
          title: i18n.t("pageMediaSuccessStories.education.title")+" "+i18n.t("pageMediaSuccessStories.project"),
          page: "education"
        },
        {
          title: i18n.t("pageMediaSuccessStories.finance.title")+" "+i18n.t("pageMediaSuccessStories.project"),
          page: "finance"
        },
        {
          title: i18n.t("pageMediaSuccessStories.gapsi.title")+" "+i18n.t("pageMediaSuccessStories.story"),
          page: "gapsi"
        },
        {
          title: i18n.t("pageMediaSuccessStories.chubb.title")+" "+i18n.t("pageMediaSuccessStories.story"),
          page: "chubb"
        }
      ]

    const currentIndex = stories.findIndex(story => story.page === nameProject);
    const previousIndex = (currentIndex - 1 + stories.length) % stories.length;
    const nextIndex = (currentIndex + 1) % stories.length;

    return (
    <>
        <div className="desktop:px-24 ml-4">
            <div className="flex items-center mt-16 mb-16 gap-5 ">
                <p className={windowWidth > 1025 ?" text_SharePost" : "text_SharePost"}>{i18n.t("pageMediaSuccessStories.sharePost")}</p>
                <a
                href={`https://x.com/intent/tweet?url=${encodeURIComponent(
                    currentUrl
                )}&text=${encodeURIComponent("This post is awesome! look at it. ")}`}
                target="_blank"
                rel="noopener noreferrer"
                >  
                    <img src={twitterIcon} alt="ttIcon" />
                </a>
                <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                >
                <img src={linkedinIcon} alt="lKIcon" />
                </a>
                <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    currentUrl
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                >
                <img src={facebookIcon} alt="fbIcon" />
                </a>
                <a
                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                    currentUrl
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                >
                <img src={whatsappIcon} alt="whatsappIcon" />
                </a>
            </div>
        </div>

        <div className="flex justify-between items-center desktop:px-24 ml-4 mr-4 mb-48 mt-24">
            <Link to={`/open-success-stories/${stories[previousIndex].page}`}>

            <div className="flex gap-8">
                
                <button >
                        <img src={arrowLeftShort} alt="arrowL" className="flex-1 pd_arroesSuccessStories" />
                </button>
            
                <div className="hidden tablet:block text-left">
                    <p className="textBtnsPrevNext">{i18n.t("pageMediaSuccessStories.previousBtn")}</p>
                    <p className="text-gray-500 font-avenir-light text-16 w-3/4 mt-6">{stories[previousIndex].title}
                    </p>
                </div>
                <div className="tablet:hidden text-left">
                    <p className="textBtnsPrevNext">{i18n.t("pageMediaBlogArticle.carruselBtns.btn1Mobile")}</p>
                    <p className="text-gray-500 font-avenir-light text-16 w-3/4 mt-6">{stories[previousIndex].title}
                    </p>
                </div>
            </div>
            </Link>


            <Link to={`/open-success-stories/${stories[nextIndex].page}`}>
            <div className="flex justify-end gap-8">
                <div className="hidden tablet:flex flex-col text-right">
                    <p className="textBtnsPrevNext">{i18n.t("pageMediaSuccessStories.nextBtn")}</p>
                    <p className="text-gray-500 font-avenir-light text-16 mt-6 w-3/4 self-end">{stories[nextIndex].title}
                    </p>
                </div>
                <div className="tablet:hidden flex flex-col text-right">
                    <p className="textBtnsPrevNext">{i18n.t("pageMediaBlogArticle.carruselBtns.btn2Mobile")}</p>
                    <p className="text-gray-500 font-avenir-light text-16 mt-6 w-3/4 self-end">{stories[nextIndex].title}
                    </p>
                </div>

              
                <button>
                        <img className="pd_arroesSuccessStories" src={arrowRigthShort} alt="arrowR" />
                </button>
  
            </div>
            </Link>

        </div>
    </>
    );
};

export default BtnsOpenSuccessStory;
