import React, { useContext } from "react";
import i18n from "i18next";
import { LoadingContext } from "../context/LoadingContext";

const TechResultsOpenSuccessStory = ({arrayData}) => {
    const { windowWidth } = useContext(LoadingContext);

    const formatResult = (result) => {
        const parts = result.split(":");
        if (parts.length > 1) {
            return (
                <p className="textResults" key={result}>
                    <span className="font-bold">{parts[0]}:</span> <span>{parts.slice(1).join(":")}</span>
                </p>
            );
        } else {
            return (
                <p className="textResults" key={result}>
                    {result}
                </p>
            );
        }
    };

  return (
    <div className="desktop:px-24 ml-4">
            
            <div className={windowWidth>1025 ? "tech-results-container": "tech-results-containerMobile"}>
                <div className={windowWidth>1025 ? "results-section text-left pr-4": "results-sectionMobile text-left pr-4"}>
                    <p className="titleResults">{i18n.t("pageMediaSuccessStories.titleResults")}</p>
                    <div className="results-box">
                    {arrayData.results && arrayData.results.length > 0 ? (
                        arrayData.results.map((result) => formatResult(result))
                        ) : (
                            null
                    )}
                    </div>
                </div>
                <div className={windowWidth>1025 ? "tech-section text-left pl-4": "tech-sectionMobile text-left pl-4"}>
                    <p className="titleTech">{i18n.t("pageMediaSuccessStories.titleTechnologies")}</p>
                    <div className="tech-images">
                        {(arrayData.tech || []).map((techImage, index) => (
                            <img className="techimgSuccessStory" key={index} src={techImage} alt={`Tech ${index + 1}`} />
                        ))}
                    </div>
                </div>
            </div>
            
        
    </div>
  );
};

export default TechResultsOpenSuccessStory;