import React, { Fragment, useState, useContext } from "react";
import { LoadingContext } from "../context/LoadingContext";
import i18n from "i18next";
import { Link } from "@reach/router";

const BannerOpenSuccessStorie = ({ logoImage, arrayTitle, nameProject }) => {
  const [dinamicHeight, setDinamicHeight] = useState(0);
  const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0);
  const { windowWidth } = useContext(LoadingContext);

  const isLogoEducationOrFinance =
    nameProject === "education" || nameProject === "finance";

  return (
    <Fragment>
      <div className="container-banner-careers">
        <div className={"shared-banner-container"}>
          {windowWidth >= 1025 ? (
            <div
              className={
                windowWidth >= 1700
                  ? "flex mobile:flex-col-reverse desktop:flex-row bannerPrincipalVacantResponsive"
                  : "flex mobile:flex-col-reverse desktop:flex-row bannerPrincipalVacant"
              }
            >
              <div
                ref={(el) => {
                  if (el) {
                    const resizeObserver = new ResizeObserver((entries) => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height);
                    });
                    resizeObserver.observe(el);
                  }
                }}
                className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16"
              >
                <div className="flex flex-col txt-our-adn txt-our-adn-movil">
                  <h3 className="inline-block desktop:w-10/12 desktop:mt-20 desktop:text-left mobile:text-left mb-4 mobile:mx-2 desktop:mx-24">
                    <span className="textTitleVacant desktop:flex">
                      <p className="subtitleVacant">
                        {i18n.t("pageMediaSuccessStories.succes")}
                      </p>
                    </span>
                    <span className="titleSuccessStory">
                      <p className="colorTextExperience2">{arrayTitle.title}</p>
                    </span>
                    <Link to="x-media-success-stories">
                      <button
                        className="buttonBackSuccessStories"
                        style={{ alignSelf: "center" }}
                      >
                        <p className="textButtomSearchWork">
                          {i18n.t("pageMediaSuccessStories.btnBack")}
                        </p>
                      </button>
                    </Link>
                  </h3>
                </div>
              </div>
              {windowWidth > 1700? (
                    <>
                    {isLogoEducationOrFinance ? (
                        <div
                            className="logo-image-containerSpecial mSuccessStorieBig"
                            style={{ backgroundImage: `url(${logoImage})` }}
                        />
                    ) : 
                        <div className="logo-image-container mSuccessStorieBig">
                            <img src={logoImage} alt="Logo" />
                        </div>
                    }
                    </>
                ):(
                    <>
                    {isLogoEducationOrFinance ? (
                         <div
                            className="logo-image-containerSpecial mSuccessStorie"
                            style={{ backgroundImage: `url(${logoImage})` }}
                        />
                    ) : 
                        <div className="logo-image-container mSuccessStorie">
                            <img src={logoImage} alt="Logo" />
                        </div>
                    }
                    </>
              )}
              
            </div>
          ) : (
            <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipaSuccesslMobile text-left">
              <div
                ref={(el) => {
                  if (el) {
                    const resizeObserver = new ResizeObserver((entries) => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height);
                    });
                    resizeObserver.observe(el);
                  }
                }}
                className="m-4 desktop:mt-4 mobile:mt-10 mobile:mb-16"
              >
                <div className="flex flex-col txt-our-adn txt-our-adn-movil">
                  <h3 className="inline-block mt-1  desktop:text-left mobile:text-left mb-4 mobile:mx-2 desktop:mx-24">
                    <span className="textTitleVacant desktop:flex">
                      <p className="subtitleVacant">
                        {i18n.t("pageMediaSuccessStories.succes")}
                      </p>
                    </span>
                    <span className="titleSuccessStory">
                      <p className="colorTextExperience2">{arrayTitle.title}</p>
                    </span>
                    <Link to="x-media-success-stories">
                      <button
                        className="buttonBackSuccessStoriesMobile"
                        style={{ alignSelf: "center" }}
                      >
                        <p className="textButtomSearchWork">
                          {i18n.t("pageMediaSuccessStories.btnBack")}
                        </p>
                      </button>
                    </Link>
                  </h3>
                </div>
              </div>
              {isLogoEducationOrFinance ? (
                 <div
                 className="logo-image-containerSpecialMobile"
                 style={{ backgroundImage: `url(${logoImage})` }}
             />
              ) :
                <div className="logo-image-containerMobile">
                    <img src={logoImage} alt="Logo" />
                </div>}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

BannerOpenSuccessStorie.defaultProps = {
  flag: true,
  clase: "shared-banner-footer banner-text-white mt-8",
  style: {},
};

export default BannerOpenSuccessStorie;
